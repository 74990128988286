@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

* html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient-bg-hero {
  background-color: white;
  /* background-image: radial-gradient(
      at 0% 0%,
      hsl(302deg 25% 18%) 0,
      transparent 50%
    ),
    radial-gradient(at 50% 0%, hsl(0deg 39% 30%) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsla(339, 49%, 30%, 1) 0, transparent 50%); */
}

.gradient-bg-artworks {
  background-color: white;
  /* background-image: radial-gradient(
      at 50% 50%,
      hsl(302deg 25% 18%) 0,
      transparent 50%
    ),
    radial-gradient(at 0% 0%, hsla(253, 16%, 7%, 1) 0, transparent 50%),
    radial-gradient(at 50% 50%, hsla(339, 39%, 25%, 1) 0, transparent 50%); */
}

.gradient-bg-footer {
  background-color: white;
  /* background-image: radial-gradient(
      at 0% 100%,
      hsl(0deg 39% 30%) 0,
      transparent 53%
    ),
    radial-gradient(at 50% 150%, hsla(339, 49%, 30%, 1) 0, transparent 50%); */
}

.text-gradient {
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lds-dual-ring {
  display: inline-block;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.payButton {
  background: #e32870!important;
  border-radius: 2rem!important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1.0)!important; /* black shadow */
  height: 35px!important;
  width: 85px!important;
  margin-top: 5px;
}
.payButton span {
  display: none;
}
.payButton:after {
  background-color: #e32870;
  content: "Buy";
  color: white; 
  font-size: 0.9rem;
}
